.side-bar {
    height: calc(
        100vh - 64px - 36px
    ); /* 64px represents the height of main top nav header, 36px represents the space taken by the side bar collapsible button */
}

.firm-logo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    border-top: 1px solid #f0f0f0;
}
