.nav-header {
    background-color: #262626;
}

.user-button {
    background-color: transparent !important;
    background: transparent !important;
    border: none;
    color: white !important;
}

.user-button:hover {
    background-color: transparent !important;
    background: transparent !important;
    color: white !important;
}

.header-oddr-logo {
    width: 88px;
    margin-left: 32px;
}
