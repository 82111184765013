:root {
    --annotationInputBackground: #fff8b6;
    --annotationTextColor: #f0f0f0;
    /* This is antd's default font family which can be used for components that overrides
    the antd's font family */
    --global-font-family:
        -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.ant-layout-sider-children {
    background: #ffffff;
    height: 100%;
    border-right: 1px solid #f0f0f0;
}

.ant-layout-sider {
    background: #ffffff;
}

.ant-menu-inline {
    border-right: none;
}

.collapse {
    border: none;
    font-size: 12px;
    z-index: 20;
    box-shadow:
        0px 13px 5px rgba(0, 0, 0, 0.01),
        0px 7px 4px rgba(0, 0, 0, 0.05),
        0px 3px 3px rgba(0, 0, 0, 0.09),
        0px 1px 2px rgba(0, 0, 0, 0.1),
        0px 0px 0px rgba(0, 0, 0, 0.1);
}
