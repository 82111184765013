@import '~antd/dist/reset.css';

.ant-page-header {
    padding-top: 0px;
    padding-left: 0px;
}

.container,
.site-container {
    position: relative;
    padding: 24px 0;
}

.site-footer {
    background: #f9f9f9;
    text-align: right;
    font-size: 12px !important;
    padding: 12px;
}

.ant-table-footer {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
}

@media (min-width: 476px) {
    .site-container {
        padding-right: 14px;
        padding-left: 15px;
    }
}

@media (min-width: 768px) {
    .site-container {
        padding-right: 14px;
        padding-left: 15px;
    }
}

@media (min-width: 992px) {
    .site-container {
        padding-right: 14px;
        padding-left: 15px;
    }
}

@media (min-width: 1200px) {
    .site-container {
        padding-right: 14px;
        padding-left: 24px;
    }
}

/* @media (min-width: 476px) {
    .site-container {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .site-container {
        width: 720px;
        max-width: 100%;
    }
}

@media (min-width: 992px) {
    .site-container {
        width: 960px;
        max-width: 100%;
    }
}

@media (min-width: 1200px) {
    .site-container {
        width: 1140px;
        max-width: 100%;
    }
}

@media (min-width: 1400px) {
    .site-container {
        width: 1340px;
        max-width: 100%;
    }
} */

.dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
}

.disabled-icon {
    color: #cccccc;
    padding: 6px;
    cursor: not-allowed;
}

.clickable-icon {
    padding: 6px;
    cursor: pointer;
}

.clickable-icon:hover {
    border-radius: 50%;
    background-color: #f0f0f0;
}

.ant-menu-submenu-selected > .ant-menu-submenu-title {
    font-weight: bold;
}

.ant-menu-item-selected {
    font-weight: bold;
}

/*Invoices and statement related*/
.statistics-wrapper {
    background: #f9f9f9;
}

.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    background-color: #ffffff !important;
}

.filter-checkbox {
    position: absolute;
    right: 8px;
    top: 12px;
}

.filter {
    cursor: pointer;
}

.selected {
    border-color: #3093d1;
    border-width: 1px;
}

.badge {
    padding: 0px 16px;
    font-size: 12px;
    margin-top: 0px;
}

.badge .ant-ribbon-text {
    padding: 0 10px;
}

.document-details-actions {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    padding: 24px 0;
    width: 64px;
    max-width: 64px;
    display: inline-block;
    box-shadow:
        0px 9px 28px 0px rgba(0, 0, 0, 0.05),
        0px -20px 16px 0px rgba(0, 0, 0, 0.08);
}

.error-alert {
    margin-bottom: 15px;
}

.error-paragraph {
    margin-bottom: 0px !important;
}

.upload_wrapper {
    width: 100%;
}

.upload_actions_wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    margin-top: 10px;
    padding-top: 10px;
    gap: 10px;
}

.upload_button {
    width: 100%;
}

.ant-upload {
    width: 100%;
}

.margin-bottom12 {
    margin-bottom: 12px;
}

.document-details-action-area-border {
    border-left: 1px solid #f0f0f0;
}

.document-details-action-area {
    margin: -24px 0;
    /* text-align: center; */
    width: fit-content;
    display: inline-block;
    min-height: 850px;
}

.document-details-action-selectors {
    border-bottom: 1px solid #f0f0f0;
}

.modal-warning-icon {
    margin-right: 8px;
    color: #faad14;
    font-size: 21px;
    vertical-align: text-bottom;
}

/* Scroll bar */

.modified-scroll-bar-hover::-webkit-scrollbar {
    width: 3px;
}

.modified-scroll-bar-hover:hover::-webkit-scrollbar {
    width: 3px;
}

.modified-scroll-bar-hover:hover::-webkit-scrollbar-thumb {
    background-color: #b3b3b3;
    border-radius: 6px;
}

.modified-scroll-bar::-webkit-scrollbar {
    width: 4px;
}

.modified-scroll-bar::-webkit-scrollbar-thumb {
    background-color: #b3b3b3;
    border-radius: 6px;
}

.need-attention-button-active {
    background-color: #fbeded;
    border: 1px solid #e99191;
}

.list-action-button {
    padding: 0px 15px;
    height: 20px;
}

.list-action-button-icon {
    font-size: 20px;
}
